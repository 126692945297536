<template>
  <div class="customerService">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source>
      <div class="navTitle">在线客服</div>
      <div class="rightBtn"></div>
    </div>
    <div class="mianBox" v-if="kefuUrl">
      <iframe class="iframe" :src="kefuUrl" frameborder="0"></iframe>
    </div>
    <Loading v-else/>
  </div>
</template>

<script>
  import {
    getService
  } from '@/api/user'
  import Loading from "@/components/Loading"
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        kefuUrl: "",
        isRequest: false,
      }
    },
    created() {
      this.getService();
    },
    methods: {
      // 返回
      backPage() {
        this.$router.go(-1);
      },
      async getService() {
        this.isRequest = true;
        try {
          let res = await this.$Api(getService);
          this.isRequest = false;
          if (res.code == 200) {
            let baseUrl = this.$store.getters.baseUrl
            let url = baseUrl.slice(0, baseUrl.length - 1);
            this.kefuUrl = url + res.data;
          }
        } catch (e) {
          this.isRequest = false;
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .customerService {
    height: 100%;
    width: 100%;

    .navBar {
      height: 44px;
      padding: 0 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e6e6e6;

      .navArrow {
        width: 17px;
        height: 17px;
      }

      .navTitle {
        flex: 2;
        text-align: center;
        font-size: 18px;
        color: #000;
      }

      .rightBtn {
        width: 17px;
        height: 17px;
      }
    }

    .mianBox {
      width: 100%;
      height: calc(100% - 44px);

      .iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>